import { createRoot } from 'react-dom/client';

import TagManager from 'react-gtm-module';

import 'focus-visible';
import './styles.css';
import './shared/styles/ellipsis-dots.css';
import './shared/styles/fonts.css';
import './shared/styles/loading-dot.css';
import './shared/styles/loader.css';
import './shared/styles/emoji-picker.css';
import './shared/styles/date-picker.css';
import './shared/styles/react-phone-input.css';
import './twitter-pixel';
import './facebook-pixel';

import { MaintenanceMode } from './pages/maintenance-mode';

import { isMaintenanceModeEnabled } from './shared/config';
import { environment } from './environments/environment';

import App from './app';

if (environment.gtmId) {
  TagManager.initialize({ gtmId: environment.gtmId });
}

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(isMaintenanceModeEnabled ? <MaintenanceMode /> : <App />);

import { MAX_CHUNK_SIZE } from '../config';

const getTotalLength = (buffers: ArrayBuffer[]): number => {
  return buffers.reduce((sum, buf) => sum + buf.byteLength, 0);
};

export const concatenateBuffers = (
  originalBuffer: Uint8Array,
  newBuffers: ArrayBuffer[]
): Uint8Array => {
  const totalLength = originalBuffer.byteLength + getTotalLength(newBuffers);
  const result = new Uint8Array(totalLength);

  // Copy original buffer
  result.set(originalBuffer, 0);

  // Copy new buffers sequentially
  let offset = originalBuffer.byteLength;
  for (const buffer of newBuffers) {
    result.set(new Uint8Array(buffer), offset);
    offset += buffer.byteLength;
  }

  return result;
};

export const checkIsDivisible = (number: number) =>
  number % MAX_CHUNK_SIZE === 0;

export const getStartEndBytes = (position: number) => {
  return {
    start: MAX_CHUNK_SIZE * position,
    end: MAX_CHUNK_SIZE * (position + 1),
  };
};

import { Modal, Button } from '../../../../shared/ui';
import { useDispatch, useSelector } from 'react-redux';
import { pagesModel } from '../../../../features/pages';

export const EditorContentErrorModal = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(pagesModel.selectors.selectIsEditorContentError);

  const onModalClose = () => {
    dispatch(pagesModel.actions.setIsEditorContentError(false));
    window.location.reload();
  };

  return (
    <Modal
      onClose={() => null}
      isOpen={isOpen}
      title="Page refresh required"
      isShowCancelButton={false}
      isActionButtonsAlignEnd
      actionButton={
        <Button
          onClick={onModalClose}
          type="submit"
          color="primary"
          variant="text"
          className="w-42"
        >
          Refresh
        </Button>
      }
    >
      <p className="text-sm text-gray-600">
        A quick refresh is needed to ensure everything is up-to-date. Your data
        is safe and everything will be back to normal.
      </p>
    </Modal>
  );
};

import { isAxiosError } from 'axios';
import { getQueryParam, logger, matchParams } from '../../../../shared/lib';
import { call, put, select } from 'redux-saga/effects';
import { createNotification, snackbarModel } from '../../../snackbar';
import { handleError } from '../../../../utils/handleError';
import {
  ACCEPT_PAGE_INVITATION_ROUTE,
  history,
  redirect,
} from '../../../../entities/history';
import { RouteParams } from '../types';
import { RT } from '../../../../shared/types';
import { authUserModel, setUserData } from '../../../../entities/auth-user';
import { teamsApi } from '../../../../shared/api';
import { TeamFiltered, UserOnboardingStatus } from '@distribute/shared/types';
import { auth } from '../../../../shared/config';
import { AcceptPageInvitationResponseType } from '@distribute/shared/api-types/team';
import { sendEmailVerification } from '../../../auth/email-verification';
import { teamsModel } from '../../../teams';
import { refreshTeamRelatedData } from '../../../teams/model/sagas';
import {
  QUERY_PARAM_ASK_TO_EDIT,
  QUERY_PARAM_EXTERNAL_PAGE_ID,
} from '../../../accept-team-invitation';
import { pagesModel } from '../../../pages';

export function* handleAcceptPageInvitationSignedIn() {
  try {
    const { pathname } = history.location;
    const params = matchParams<RouteParams>(
      pathname,
      ACCEPT_PAGE_INVITATION_ROUTE
    );

    if (!params) return;

    const askToEdit = getQueryParam(QUERY_PARAM_ASK_TO_EDIT);
    const externalPageId = getQueryParam(QUERY_PARAM_EXTERNAL_PAGE_ID);

    const dbUser: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const {
      team,
      pageSequenceNumber,
      shouldVerifyEmail,
    }: AcceptPageInvitationResponseType = yield call(
      teamsApi.acceptPageInvitation,
      params.pageInviteId
    );

    if (shouldVerifyEmail) {
      yield call(sendEmailVerification, `/editor/${pageSequenceNumber}`);
    }

    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'Invite has been accepted')
      )
    );

    yield put(
      authUserModel.actions.setDBUser({
        ...dbUser,
        onboardingStatus: shouldVerifyEmail
          ? UserOnboardingStatus.VERIFY_EMAIL
          : UserOnboardingStatus.FINISHED,
      })
    );

    yield auth.currentUser?.reload();

    const teams: TeamFiltered[] = yield call(teamsApi.getTeams);

    yield put(teamsModel.actions.setTeams(teams));

    const currentTeam = teams.find((t) => t.id === team.id);

    yield put(teamsModel.actions.setCurrentTeam(currentTeam));

    yield call(setUserData);

    yield call(refreshTeamRelatedData);

    if (askToEdit && externalPageId) {
      yield put(
        pagesModel.actions.requestToEditPage({ pageId: externalPageId })
      );
    }

    yield call(redirect.toEditorPage, {
      sequenceNumber: pageSequenceNumber,
      isKeepQueryParams: false,
    });

    return;
  } catch (e) {
    logger.error(e);
    if (isAxiosError(e)) {
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification(
            'error',
            handleError(e, 'Failed to accept page invitation')
          )
        )
      );
      yield call(redirect.toWorkspace);
    }
    return;
  }
}

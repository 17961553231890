import classNames from 'classnames';
import {
  ViewerDetails,
  Conversion,
  TabActivity,
} from '@distribute/shared/types';
import { formatLocation, formatMsToTimePassed } from '@distribute/shared/utils';

import React, { FC } from 'react';

import moment from 'moment';
import { IconMap } from '../../../../shared/sprite';
import { Icon } from '../../../../shared/ui';

type Props = {
  className?: string;
  item: ViewerDetails;
};

export const SectionContact: FC<Props> = ({
  className,
  item: { location, name, email, phone },
}) => {
  const isAnonymous = email === null;

  return (
    <div className={classNames('flex flex-col gap-y-3', className)}>
      <h6 className="uppercase text-xs font-bold text-gray-700 truncate">
        Contact
      </h6>
      {(isAnonymous || name) && (
        <div>
          <p className="text-xs text-gray-500 truncate">Name</p>
          <p className="text-sm text-gray-900 truncate">
            {name ?? 'Anonymous'}
          </p>
        </div>
      )}
      {email && (
        <div>
          <p className="text-xs text-gray-500 truncate">Email</p>
          <p className="text-sm text-gray-900 truncate">{email}</p>
        </div>
      )}
      {phone && (
        <div>
          <p className="text-xs text-gray-500 truncate">Phone</p>
          <p className="text-sm text-gray-900 truncate">{phone}</p>
        </div>
      )}
      {location && (
        <div>
          <p className="text-xs text-gray-500 truncate">Location</p>
          <p className="text-sm text-gray-900 truncate">
            {formatLocation(location)}
          </p>
        </div>
      )}
    </div>
  );
};

export const SectionActivity: FC<Props> = ({ item, className }) => {
  return (
    <div className={classNames('flex flex-col gap-y-3', className)}>
      <h6 className="uppercase text-xs font-bold text-gray-700">
        Overall Activity
      </h6>
      <div>
        <p className="text-xs text-gray-500 truncate">Date added</p>
        <p className="text-sm text-gray-900 truncate">
          {moment(item.createdAt).format('MMM D, YYYY, h:mm A')}
        </p>
      </div>
      <div>
        <p className="text-xs text-gray-500 truncate">Last visit</p>
        <p className="text-sm text-gray-900 truncate">
          {moment(item.lastVisitAt).format('MMM D, YYYY, h:mm A')}
        </p>
      </div>
      <div>
        <p className="text-xs text-gray-500 truncate">Views</p>
        <p className="text-sm text-gray-900 truncate">{item.views || 1}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500 truncate">Overall activity</p>
        <p className="text-sm text-gray-900 truncate">
          {item.overallActivity
            ? formatMsToTimePassed(item.overallActivity)
            : '-'}
        </p>
      </div>
    </div>
  );
};

export const SectionMessage: FC<Props> = ({ item, className }) => {
  const isShow = Boolean(item.message);

  if (!isShow) return null;

  return (
    <div className={classNames('flex flex-col gap-y-3', className)}>
      <h6 className="uppercase text-xs font-bold text-gray-700 truncate">
        Message(s)
      </h6>
      <p className="text-sm text-gray-900 ">{item.message}</p>
    </div>
  );
};

export const SectionGeneralInfo: FC<Props> = ({ item, className }) => {
  return (
    <div className={classNames('flex flex-col gap-y-3', className)}>
      <h6 className="uppercase text-xs font-bold text-gray-700 truncate">
        General Info
      </h6>
      {item.email && (
        <div>
          <p className="text-xs text-gray-500 truncate">Email</p>
          <p className="text-sm text-gray-900 truncate">{item.email}</p>
        </div>
      )}
      <div>
        <p className="text-xs text-gray-500 truncate">Date added</p>
        <p className="text-sm text-gray-900 truncate">
          {moment(item.createdAt).format('MMM D, YYYY, h:mm A')}
        </p>
      </div>
      <div>
        <p className="text-xs text-gray-500 truncate">Last visit</p>
        <p className="text-sm text-gray-900 truncate">
          {moment(item.lastVisitAt).format('MMM D, YYYY, h:mm A')}
        </p>
      </div>
      <div>
        <p className="text-xs text-gray-500 truncate">Views</p>
        <p className="text-sm text-gray-900 truncate">{item.views || 1}</p>
      </div>
      <div>
        <p className="text-xs text-gray-500 truncate">Overall activity</p>
        <p className="text-sm text-gray-900 truncate">
          {item.overallActivity
            ? formatMsToTimePassed(item.overallActivity)
            : '-'}
        </p>
      </div>
      {item.location && (
        <div>
          <p className="text-xs text-gray-500 truncate">Location</p>
          <p className="text-sm text-gray-900 truncate">
            {formatLocation(item.location)}
          </p>
        </div>
      )}
    </div>
  );
};

export const SectionSource: FC<Props> = ({ item, className }) => {
  const { conversions } = item;
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  if (!Array.isArray(conversions) || conversions.length < 1) return null;

  const isPrevDisabled = selectedIndex === 0;
  const isNextDisabled = selectedIndex === conversions.length - 1;
  const shouldShowSwitcher = conversions.length > 1;

  const handleClicked = (index: number) => {
    setSelectedIndex((prev) => {
      const newIndex = Math.min(
        conversions.length - 1,
        Math.max(0, prev + index)
      );
      return newIndex;
    });
  };

  return (
    <div className={classNames('flex flex-col gap-y-3', className)}>
      <div className="flex items-center justify-between">
        <h6 className="uppercase text-xs font-bold text-gray-700 truncate">
          Source
        </h6>
        {shouldShowSwitcher && (
          <div className="flex gap-2">
            <span>
              <span className="font-semibold">{selectedIndex + 1}</span> of{' '}
              {conversions.length}
            </span>
            <div className="text-gray-600">
              <button
                className={classNames('border-0 outline-0 rounded-md', {
                  'hover:bg-base-black/10': !isPrevDisabled,
                  'text-gray-400': isPrevDisabled,
                })}
                onClick={() => handleClicked(-1)}
              >
                <Icon
                  glyph={IconMap.ChevronLeft}
                  width={20}
                  className="shrink-0"
                />
              </button>
              <button
                className={classNames('border-0 outline-0 rounded-md', {
                  'hover:bg-base-black/10': !isNextDisabled,
                  'text-gray-400': isNextDisabled,
                })}
                onClick={() => handleClicked(+1)}
              >
                <Icon
                  glyph={IconMap.ChevronRight}
                  width={20}
                  className="shrink-0"
                />
              </button>
            </div>
          </div>
        )}
      </div>
      <SourceItem {...conversions[selectedIndex]} />
    </div>
  );
};

const SourceItem: FC<Conversion> = (conversion) => {
  return (
    <div className="flex flex-col gap-y-3">
      <div className="border-b border-gray-200 pb-2">
        <h5 className="text-sm text-gray-900 font-semibold truncate">
          {conversion.source}
        </h5>
        <p className="text-sm text-gray-500 truncate">
          {moment(conversion.createdAt).format('hh:mm A • MMM D, YYYY')}
        </p>
      </div>

      <div className="flex flex-col gap-y-3 pl-3">
        {conversion.email && (
          <div>
            <p className="text-xs text-gray-500 truncate">Email</p>
            <p className="text-sm text-gray-900 truncate">{conversion.email}</p>
          </div>
        )}
        {conversion.name && (
          <div>
            <p className="text-xs text-gray-500 truncate">Name</p>
            <p className="text-sm text-gray-900 truncate">{conversion.name}</p>
          </div>
        )}
        {conversion.phone && (
          <div>
            <p className="text-xs text-gray-500 truncate">Phone</p>
            <p className="text-sm text-gray-900 truncate">{conversion.phone}</p>
          </div>
        )}
        {conversion.message && (
          <div>
            <p className="text-xs text-gray-500 truncate">Phone</p>
            <p className="text-sm text-gray-900">{conversion.message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export const SectionTabActivity: FC<Props> = ({ item, className }) => {
  if (!Array.isArray(item.tabActivity) || item.tabActivity.length < 1)
    return null;

  const activities = item.tabActivity.map(
    ({ overallActivity }) => overallActivity
  );
  const maxActivity = Math.max.apply(null, activities);

  return (
    <div className={classNames('flex flex-col gap-y-3', className)}>
      <h6 className="uppercase text-xs font-bold text-gray-700 truncate">
        Tab Activity
      </h6>
      <div className="flex flex-col gap-y-3">
        {item.tabActivity.map((activity) => (
          <TabActivityItem
            key={activity.id}
            tabActivity={activity}
            max={maxActivity}
          />
        ))}
      </div>
    </div>
  );
};

const TabActivityItem = ({
  tabActivity,
  max,
}: {
  tabActivity: TabActivity;
  max: number;
}) => {
  const width = (tabActivity.overallActivity / max) * 100;

  return (
    <div>
      <div className="flex justify-between mb-1">
        <span>{tabActivity.name}</span>
        <span>{formatMsToTimePassed(tabActivity.overallActivity)}</span>
      </div>
      <div
        className="bg-primary-500 rounded-sm"
        style={{ width: `${width}%`, height: '6px' }}
      ></div>
    </div>
  );
};

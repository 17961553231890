import { FirebaseUser } from '../../../../shared/types';
import { getUserFromFb, setUserData } from '../../../../entities/auth-user';
import { call, put } from 'redux-saga/effects';
import { getQueryParam } from '../../../../shared/lib';
import {
  QUERY_PARAM_ASK_TO_EDIT,
  QUERY_PARAM_EXTERNAL_PAGE_ID,
} from '../../../accept-team-invitation';
import { teamsApi } from '../../../../shared/api';
import { JoinTeamPageAsGuestResponseType } from '@distribute/shared/api-types/team';
import { TeamFiltered } from '@distribute/shared/types';
import { teamsModel } from '..';
import { redirect } from '../../../../entities/history';
import { refreshTeamRelatedData } from './loadTeamRelatedData';
import { pagesModel } from '../../../pages';

export function* handleJoinTeamPageAsGuest() {
  const externalPageId = getQueryParam(QUERY_PARAM_EXTERNAL_PAGE_ID);
  const askToEdit = getQueryParam(QUERY_PARAM_ASK_TO_EDIT);

  if (!externalPageId) {
    return;
  }

  const fbUser: FirebaseUser = yield getUserFromFb();

  if (!fbUser.emailVerified) return;

  const { teamId, sequenceNumber }: JoinTeamPageAsGuestResponseType =
    yield call(teamsApi.joinTeamPageAsGuest, externalPageId);

  const teams: TeamFiltered[] = yield call(teamsApi.getTeams);

  yield put(teamsModel.actions.setTeams(teams));

  const currentTeam = teams.find((team) => team.id === teamId);

  yield put(teamsModel.actions.setCurrentTeam(currentTeam));

  yield call(setUserData);

  yield call(refreshTeamRelatedData);

  if (askToEdit) {
    yield put(pagesModel.actions.requestToEditPage({ pageId: externalPageId }));
  }

  yield call(redirect.toEditorPage, {
    sequenceNumber,
    isKeepQueryParams: false,
  });
}
